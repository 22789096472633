import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { devices } from 'assets/styled/tokens/devices';
import tooltipArrow from 'assets/icons/tooltip-arrow.svg';

const TooltipText = styled.span`
  visibility: hidden;
  width: 160px;
  text-align: left;
  background-color: #666666;
  color: #fff;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 42px;
  left: 24%;
  margin-left: -75px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  &:after {
    content: url(${tooltipArrow});
    display: inline-flex;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -3px;
    border-width: 3px;
    width: 1px;
    overflow: hidden;
    border-style: solid;
    border-color: #666666 transparent transparent transparent;
  }
  @media ${devices.tabletLandscape} {
    margin-left: -137px;
    width: 291px;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  :hover span {
    visibility: visible;
  }
`;

function Tooltip({ text, className = '' }) {
  return (
    <TooltipContainer className={className}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 0C3.13306 0 0 3.16129 0 7C0 10.8669 3.13306 14 7 14C10.8387 14 14 10.8669 14 7C14 3.16129 10.8387 0 7 0ZM7 12.6452C3.86694 12.6452 1.35484 10.1331 1.35484 7C1.35484 3.89516 3.86694 1.35484 7 1.35484C10.1048 1.35484 12.6452 3.89516 12.6452 7C12.6452 10.1331 10.1048 12.6452 7 12.6452ZM10.0202 5.44758C10.0202 4.03629 8.52419 2.93548 7.14113 2.93548C5.81452 2.93548 4.96774 3.5 4.31855 4.4879C4.20565 4.62903 4.23387 4.82661 4.375 4.93952L5.16532 5.53226C5.30645 5.64516 5.53226 5.61694 5.64516 5.47581C6.06855 4.93952 6.37903 4.62903 7.02823 4.62903C7.53629 4.62903 8.15726 4.93952 8.15726 5.44758C8.15726 5.81452 7.84677 5.98387 7.33871 6.26613C6.77419 6.60484 6.0121 7 6.0121 8.01613V8.24193C6.0121 8.43952 6.15323 8.58064 6.35081 8.58064H7.62097C7.81855 8.58064 7.95968 8.43952 7.95968 8.24193V8.07258C7.95968 7.36694 10.0202 7.33871 10.0202 5.44758ZM8.18548 10.1613C8.18548 9.5121 7.64919 8.97581 7 8.97581C6.32258 8.97581 5.81452 9.5121 5.81452 10.1613C5.81452 10.8387 6.32258 11.3468 7 11.3468C7.64919 11.3468 8.18548 10.8387 8.18548 10.1613Z"
          fill="#AAAAAA"
        />
      </svg>
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Tooltip };
