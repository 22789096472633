import { AxiosGlobalFilters, AxiosMediaCenter } from '../config';

export const RegistrationService = {
  getNewMediaPath: ({ mediaType, fileExtension, empresaId, token }) => {
    let url = `v1/storage/${empresaId}/newPath?type=${mediaType}`;

    if (fileExtension) {
      url += `&fileExtension=${fileExtension.replace('.', '')}`;
    }

    return AxiosMediaCenter({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  uploadFileToApi: ({ filePath, file, token }) => {
    const formData = new FormData();
    formData.append('files', file, filePath);
    return AxiosMediaCenter({
      method: 'POST',
      url: 'v1/storage/upload',
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data',
      },
    });
  },
  sendToHomepageEmpresa: ({ data, token }) => {
    return AxiosGlobalFilters({
      method: 'POST',
      url: '/v1/homepageempresa',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getCompanieInfo: ({ EmpresaId, token }) => {
    let url = `/v1/homepageempresa/${EmpresaId}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getCity: ({ token, Nome }) => {
    let url = `/v1/Cidades?Nome=${Nome}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
