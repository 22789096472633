import React from 'react';

import { UserMenu } from 'components/UserMenu';

import { URLS } from 'constants/urls';

import {
  StyledHeader,
  StyledHeaderChildren,
  StyledHeaderActions,
  StyledShowUserMenu,
  RefereeButton,
  RightSideHeader,
} from './styled';

const Header = ({ children }) => {
  return (
    <StyledHeader>
      <StyledHeaderChildren>{children}</StyledHeaderChildren>
      <RightSideHeader>
        <RefereeButton
          onClick={() =>
            window.open(
              'https://leankeep.com/indica-leankeep?bl_ref=PNDPZ&utm_source=software&utm_medium=menu&utm_campaign=indicacao-clientes',
            )
          }
        >
          Indique e Ganhe
        </RefereeButton>
        <StyledShowUserMenu>
          <StyledHeaderActions>
            <a href={URLS.movidesk} target="_blank" rel="noopener noreferrer">
              Central de Ajuda
            </a>
            <UserMenu />
          </StyledHeaderActions>
        </StyledShowUserMenu>
      </RightSideHeader>
    </StyledHeader>
  );
};

export { Header };
