import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from '../../../../../Form/subcomponents/Input';
import PropTypes from 'prop-types';
import {
  StyledSelectWrapper,
  StyledSelect,
  StyledDropdownContent,
  StyledDropdownItem,
  DropdownOption,
  StyledError,
  StyledDropdownContentWrapper,
  StyledSearchBar,
} from './styled';
import MagnifyingIcon from 'assets/icons/magnifying.svg';
import CleanInputIcon from 'components/Form/shared/CleanInputIcon';
import Conditional from 'components/Conditional';

const Select = forwardRef((props, ref) => {
  const {
    size,
    placeholder,
    isActive = true,
    onChange,
    onSelect = () => {},
    value,
    options,
    className = '',
    error,
    errormessage,
    disabled,
    children,
    defaultValue,
    'data-cy': dataCy,
    hasOnlyUnitsWithSubgroup,
    $filter,
    name,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [filterWord, setFilterWord] = useState('');
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectedLabel, setSelectedLabel] = useState('');
  const selectRef = useRef(ref);
  const handleValueChange = useCallback(
    valueToFind => {
      if (valueToFind) {
        const current = options.find(option => option.value === valueToFind);
        if (current) {
          setSelectedValue(current.value);
          setSelectedLabel(current.label);
        }
      } else {
        if (name === 'pageItens') {
          setSelectedValue(5);
          setSelectedLabel('5');
        } else {
          setSelectedValue('');
          setSelectedLabel('');
        }
      }
    },
    [name, options],
  );

  useEffect(() => {
    handleValueChange(defaultValue);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleValueChange(value);
  }, [handleValueChange, value]);

  const handleChange = option => {
    setSelectedValue(option.value);
    setSelectedLabel(option.label);
    onChange && onChange(option.value);
  };

  const openDropDownContent = () => {
    if (options.length > 0) {
      setIsOpen(() => !isOpen);
    }
  };

  const filteredOptions = useMemo(() => {
    const filterOptionsByWord = option => {
      if (!filterWord) {
        return option;
      }

      const wordMatches = `${option.label}`.toLowerCase().includes(filterWord.toLowerCase());

      if (wordMatches) {
        return option;
      }

      return null;
    };
    return options.filter(option => filterOptionsByWord(option));
  }, [options, filterWord]);

  const handleClickOutside = useCallback(
    e => {
      if (selectRef.current.contains(e.target)) {
        // inside click
      } else {
        // outside click
        setIsOpen(false);
      }
    },
    [selectRef],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    setFilterWord('');
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);
  const handleKeyPress = e => {
    if (e.key === 'Enter' && isOpen) {
      // handle(options[0].value);
      e.preventDefault();
      e.stopPropagation();
      handleChange(filteredOptions[0]);
      setIsOpen(false);
    }
  };
  return (
    <StyledSelectWrapper
      isActive={isActive}
      onKeyPress={handleKeyPress}
      className={className}
      ref={selectRef}
      inputSize={size}
    >
      <StyledSelect
        readOnly
        type="text"
        placeholder={placeholder}
        size={size}
        error={error}
        dropdownDisabled={options.length === 1 && selectedValue !== '' && hasOnlyUnitsWithSubgroup}
        value={selectedLabel || ''}
        disabled={disabled}
        onClick={openDropDownContent}
        data-cy={`toggle-${dataCy}`}
        $filter={$filter}
      />
      <Conditional when={name !== 'pageItens'}>
        <CleanInputIcon
          size={size}
          onClick={() => {
            handleChange({ value: '', label: '' });
          }}
        />
      </Conditional>
      <StyledDropdownContent isOpen={isOpen}>
        <StyledSearchBar>
          <Input
            type="text"
            autoComplete="off"
            name="search"
            size="extraSmall"
            placeholder="Pesquisar"
            icon={MagnifyingIcon}
            data-cy={`search-${dataCy}`}
            onChange={e => setFilterWord(e.target.value)}
          />
        </StyledSearchBar>
        <StyledDropdownContentWrapper>
          {filteredOptions.map(option => (
            <StyledDropdownItem
              key={option.value}
              data-cy={`${dataCy}-option`}
              onClick={() => {
                setIsOpen(false);
                handleChange(option);
                onSelect(option);
                setFilterWord('');
              }}
            >
              <DropdownOption />
              <label>{option.label}</label>
            </StyledDropdownItem>
          ))}
          {children}
        </StyledDropdownContentWrapper>
      </StyledDropdownContent>
      <StyledError>
        <p>{error && errormessage}</p>
      </StyledError>
    </StyledSelectWrapper>
  );
});

Select.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  isClearInputActive: PropTypes.bool,
  filter: PropTypes.bool,
};

Select.defaultProps = {
  size: 'medium',
  isClearInputActive: true,
  options: [],
  defaultValue: '',
};

export { Select };
