import React, { useState, useContext, useEffect } from 'react';

import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';

import { URLS } from 'constants/urls';

import { StyledUserMenu } from './styled';
import { ReactComponent as UserIcon } from 'assets/icons/user-profile.svg';
const UserMenu = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);

  const { authContext } = useContext(AuthContext);
  const { user } = authContext;

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_LEGADO}/security/signout.aspx`;
  };
  const handleClick = event => {
    const { target } = event;
    const isChildrenOfUserMenu = target.closest('.user-menu');

    if (!isChildrenOfUserMenu) setUserMenuOpen(false);
  };
  const handleKeyUp = event => {
    const key = event.which || event.keyCode;

    if (key === 27) setUserMenuOpen(false);
  };
  useEffect(() => {
    document.body.addEventListener('click', handleClick);

    document.body.addEventListener('keyup', handleKeyUp);

    return function cleanup() {
      document.body.removeEventListener('click', handleClick);
      document.body.removeEventListener('keyup', handleKeyUp);

      setUserMenuOpen(false);
    };
  }, [authContext]);

  return (
    <StyledUserMenu className="user-menu">
      <button onClick={() => setUserMenuOpen(true)}>
        Olá, <span>{user?.name ? user?.name : 'usuário leankeep'}</span>
        <UserIcon />
      </button>

      {userMenuOpen && (
        <ul>
          <li>
            <a href={URLS.account} target="_blank" rel="noopener noreferrer">
              Minha Conta
            </a>
          </li>

          <li>
            <a href={URLS.tickets} target="_blank" rel="noopener noreferrer">
              Meus Tickets
            </a>
          </li>

          <li>
            <span
              role="button"
              tabIndex={0}
              onClick={() =>
                setGlobalComponentsContext({
                  ...globalComponentsContext,
                  aboutModalOpen: true,
                })
              }
              onKeyPress={() =>
                setGlobalComponentsContext({
                  ...globalComponentsContext,
                  aboutModalOpen: true,
                })
              }
            >
              Sobre
            </span>
          </li>

          <li>
            {/* eslint-disable-next-line*/}
            <span role="button" tabIndex={0} onClick={() => logout()}>
              Sair
            </span>
          </li>
        </ul>
      )}
    </StyledUserMenu>
  );
};

export { UserMenu };
