import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  CategoryHeader,
  CategoryHeaderTitle,
  FormWrapper,
  Header,
  HeaderName,
  PageContainer,
  RegisterContainerFields,
  SaveButton,
  StyledGridColumn,
  StyledInnerGridColumn,
  StyledFormGroup,
  InnerForm,
} from './styled';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';
//import { getLocalAuthStorage } from 'utils/localStorage';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageWrapper } from 'components/PageWrapper';
import { MobileNavbar } from 'components/MobileNavbar';
import { colors } from 'assets/styled/tokens';
import { NewMenu } from 'components/Menu/NewMenu';
import { Loading, Toast } from 'components';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import ImageUpload from './Components/ImageUpload';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import Checkbox from './Components/Checkbox';
import { RegistrationService } from 'services/main/endpoints/RegistrationService';
import { Select } from 'components/Form/subcomponents/DebouncedSelect';
import { createErrorToast, createGenericErrorToast, createSuccessToast } from 'utils/createToast';
import { useDebounce } from 'utils/hooks/useDebounce';
import * as Sentry from '@sentry/react';

export const CompaniesRegistrationPage = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const { globalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [toastList, setToastList] = useState([]);
  const [cities, setCities] = useState([]);
  const methods = useForm({
    defaultValues: {
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      telefone: '',
      site: '',
      instagram: '',
      linkedin: '',
      facebook: '',
      dadosPublicos: false,
      image: null,
    },
  });
  const { handleSubmit, control, reset } = methods;
  const getNewPath = async (empresaId, mediaType, fileExtension) => {
    try {
      const response = await RegistrationService.getNewMediaPath({ mediaType, fileExtension, empresaId, token });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Erro ao obter o novo caminho de mídia:', error);
      Sentry.captureException(error);
    }
  };
  const uploadImage = async (mediaPath, file) => {
    try {
      const response = await RegistrationService.uploadFileToApi({ filePath: mediaPath, file, token });
      return response.status === 200;
    } catch (error) {
      console.error('Erro ao fazer o upload:', error);
      Sentry.captureException(error);
      return false;
    }
  };
  const onSubmit = async data => {
    try {
      let imageUrl = '';
      if (!data.cidade) {
        createErrorToast(setToastList, 'Selecione uma cidade para continuar');
        return;
      }
      if (data.image && typeof data.image !== 'string') {
        const file = data.image;
        if (!headerFilters?.companies?.id) {
          createErrorToast(setToastList, 'Selecione uma empresa para continuar');
          return;
        }
        const empresaId = headerFilters?.companies?.id;
        const mediaType = 'Empresas';
        const fileExtension = file.name.split('.').pop();

        const mediaPath = await getNewPath(empresaId, mediaType, fileExtension);
        const uploadSuccess = await uploadImage(mediaPath, file);
        if (uploadSuccess) {
          imageUrl = mediaPath.replace('/uploads/', '');
        } else {
          console.error('Erro ao fazer upload da imagem.');
          Sentry.captureMessage('Erro ao fazer upload da imagem.');
          return;
        }
      }
      const payloadData = {
        empresa: headerFilters?.companies?.id,
        cep: String(data.cep),
        endereco: data.endereco,
        numero: String(data.numero),
        complemento: data.complemento,
        bairro: data.bairro,
        cidade: data.cidade.value || data.cidade,
        telefone: String(data.telefone),
        url: data.site,
        instagran: data.instagram,
        linkedin: data.linkedin,
        facebook: data.facebook,
        logoMarcaPath: imageUrl,
        habilitarQrCodePublico: data.dadosPublicos ? data.dadosPublicos : false,
      };

      const sendSuccess = await sendRegistrationData(payloadData);
      if (sendSuccess) {
        createSuccessToast(setToastList, 'Cadastro realizado com sucesso');
      } else {
        createErrorToast(setToastList, 'Ocorreu um erro e não foi possível salvar. Tente novamente.');
        Sentry.captureMessage('Erro ao enviar os dados.');
        console.error('Erro ao enviar os dados.');
      }
    } catch (error) {
      console.error('Erro no envio dos dados:', error);
      Sentry.captureException(error);
    }
  };

  const sendRegistrationData = async payloadData => {
    try {
      const response = await RegistrationService.sendToHomepageEmpresa({ data: payloadData, token });

      if (response.status === 200) {
        return true;
      } else {
        console.error('Erro ao enviar os dados para /v1/homepageempresa:', response.statusText);
        Sentry.captureException(error);
        return false;
      }
    } catch (error) {
      console.error('Erro ao enviar os dados para /v1/homepageempresa:', error);
      Sentry.captureException(error);
      return false;
    }
  };
  const handleFocus = event => {
    if (event.target.value === '') {
      event.target.setSelectionRange(0, 0);
    }
  };
  const fetchCities = async Nome => {
    try {
      const response = await RegistrationService.getCity({ Nome, token });
      const cities = response.data.map(city => ({
        label: city.nome,
        value: city.cidadeId,
      }));
      setCities(cities);
    } catch (error) {
      createGenericErrorToast(setToastList);
      console.error(error);
    }
  };

  const debouncedFetchCities = useDebounce(Nome => {
    fetchCities(Nome);
  }, 300);

  const handleChange = event => {
    if (event === '') return;
    debouncedFetchCities(event);
  };
  const populateFieldsStrategy = async () => {
    const companyId = headerFilters?.companies?.id;
    return await RegistrationService.getCompanieInfo({
      EmpresaId: companyId,
      token,
    }).then(response => {
      const data = response.data;
      return {
        cep: data.cep || '',
        endereco: data.endereco || '',
        numero: data.numero || '',
        complemento: data.complemento || '',
        bairro: data.bairro || '',
        cidade: data.cidadeObj ? { value: data.cidadeObj.cidadeId, label: data.cidadeObj.nome } : '',
        telefone: data.telefone || '',
        site: data.url || '',
        instagram: data.instagran || '',
        linkedin: data.linkedin || '',
        facebook: data.facebook || '',
        image: data.empresaObj ? data.empresaObj.logomarcaPath || '' : '',
        dadosPublicos: data.empresaObj ? data.empresaObj.habilitarQrCodePublico || '' : '',
      };
    });
  };
  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
      reset(fieldsData);
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    try {
      await populateFields();
    } catch (err) {
      if (err.message.includes('Network Error')) {
        history.push('/conection-error');
      }
      createGenericErrorToast(setToastList);
    }
  };
  useEffect(() => {
    if (headerFilters?.companies?.id) {
      fetchData();
    } else {
      createErrorToast(setToastList, 'Selecione uma empresa para continuar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerFilters?.companies?.id]);

  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <HeaderName>Cadastrar Página da Empresa</HeaderName>
          </Header>
          <PageContainer>
            <CategoryHeader>
              <CategoryHeaderTitle>Cadastro</CategoryHeaderTitle>
              <SaveButton onClick={handleSubmit(onSubmit)}>Salvar</SaveButton>
            </CategoryHeader>
            <RegisterContainerFields>
              <FormProvider {...methods}>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                  <StyledGridColumn size={1} columnGap="1rem" rowGap="1rem">
                    <FormGroup label="Logo da Empresa">
                      <ImageUpload />
                    </FormGroup>
                    <FieldRow marginBottom="2rem" />
                    <InnerForm>
                      <h3>Endereço</h3>
                      <StyledFormGroup label="CEP" width="274px">
                        <Controller
                          name="cep"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <InputMask
                              value={props.value || ''}
                              onChange={props.onChange}
                              mask="99999-999"
                              maskchar=" "
                              onFocus={handleFocus}
                              ref={null}
                              {...props}
                            ></InputMask>
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="Endereço" width="468px">
                        <Controller
                          name="endereco"
                          control={control}
                          defaultValue=""
                          render={props => <input ref={null} onChange={props.onChange} {...props} />}
                        />
                      </StyledFormGroup>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Número" width="220px">
                          <Controller
                            name="numero"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <InputMask maskchar="" mask="999999" ref={null} onChange={props.onChange} {...props} />
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Complemento">
                          <Controller
                            name="complemento"
                            control={control}
                            defaultValue=""
                            render={props => <input ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Bairro" width="220px">
                          <Controller
                            name="bairro"
                            control={control}
                            defaultValue={fields?.companie?.bairro}
                            render={props => (
                              <input ref={null} value={fields?.companie?.bairro} onChange={props.onChange} {...props} />
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Cidade" isRequired>
                          <Controller
                            name="cidade"
                            control={control}
                            //defaultValue={fields?.companie?.cidade}
                            render={props => (
                              <Select
                                {...props}
                                size="small"
                                inputDifferent={true}
                                placeholder="Seleção obrigatória"
                                options={cities}
                                onInputChange={handleChange}
                                onChange={value => {
                                  //handleChange(value);
                                  props.onChange(value);
                                  //fetchData();
                                }}
                                ref={null}
                              />
                            )}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                    </InnerForm>
                  </StyledGridColumn>
                  <StyledGridColumn size={1} columnGap="1rem" rowGap="1rem">
                    <InnerForm>
                      <h3>Contato</h3>
                      <StyledFormGroup label="Telefone" width="468px">
                        <Controller
                          name="telefone"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <InputMask
                              mask="(99) 99999-9999"
                              placeholder="( ) "
                              maskchar=""
                              onFocus={handleFocus}
                              onChange={props.onChange}
                              ref={null}
                              {...props}
                            ></InputMask>
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="Site" width="468px">
                        <Controller
                          name="site"
                          control={control}
                          defaultValue=""
                          render={props => <input ref={null} onChange={props.onChange} {...props} />}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="Instagram" width="468px">
                        <Controller
                          name="instagram"
                          control={control}
                          defaultValue=""
                          render={props => <input ref={null} onChange={props.onChange} {...props} />}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="Linkedin" width="468px">
                        <Controller
                          name="linkedin"
                          control={control}
                          defaultValue=""
                          render={props => <input ref={null} onChange={props.onChange} {...props} />}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="Facebook" width="468px">
                        <Controller
                          name="facebook"
                          control={control}
                          defaultValue=""
                          render={props => <input ref={null} onChange={props.onChange} {...props} />}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup>
                        <Controller
                          name="dadosPublicos"
                          control={control}
                          defaultValue={false}
                          render={props => (
                            <Checkbox
                              onChange={props.onChange}
                              checked={props.value}
                              name="dadosPublicos"
                              label="Tornar dados públicos"
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                    </InnerForm>
                  </StyledGridColumn>
                </FormWrapper>
              </FormProvider>
            </RegisterContainerFields>
          </PageContainer>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
};
