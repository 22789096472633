import React, { useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { getLocalAuthStorage } from 'utils/localStorage';
import { AuthContext } from 'context/AuthContext';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { usePermissions } from 'hooks/usePermission';
//import { Index } from 'pages/Index';
import jwt from 'jwt-decode';
const PrivateRoute = ({ path, Component, page, ...rest }) => {
  const { permissionContext } = usePermissions();
  const { setAuthContext } = useContext(AuthContext);
  const authStorage = getLocalAuthStorage();
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const companyId = headerFilters?.companies?.id;
  if (!authStorage || !authStorage.isAuthenticated || !authStorage.token) {
    setAuthContext({
      statusCode: false,
      isAuthenticated: false,
      token: null,
      refreshToken: null,
      expiresIn: null,
      user: {
        name: null,
        permissionType: null,
      },
    });

    window.location.replace('/login');
    return null;
  } else {
    const { token } = getLocalAuthStorage();
    const { utyp } = jwt(token);
    const tipoUsuario = JSON.parse(utyp);
    if (tipoUsuario === 1) {
      return <Route path={path} render={props => <Component {...props} companyId={companyId} />} {...rest} />;
    } else if ((page === 'push' || page === 'cadastros') && tipoUsuario === 2) {
      return <Route path={path} render={props => <Component {...props} companyId={companyId} />} {...rest} />;
    } else if (permissionContext[page] || (page === 'geolocalizacao' && tipoUsuario === 2)) {
      return <Route path={path} render={props => <Component {...props} companyId={companyId} />} {...rest} />;
    } else {
      window.location.replace('/permission-error');
      return null;
    }
  }
};

export default withRouter(PrivateRoute);
