import styled from 'styled-components';

import leankeepHat from 'assets/icons/leankeep/leankeep-hat.svg';

import { medias } from 'assets/styled/tokens/medias';
import { colors } from 'assets/styled/tokens/colors';
import { Button } from 'components/Button';
export const RefereeButton = styled(Button)`
  background: #f38a00;
  color: #ffffff;
  height: 24px;
  width: 147px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  padding: 0;
`;
export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${colors.lightGray};
  align-items: flex-start;
  justify-content: space-between;
  background: ${colors.pampas};
  z-index: 98;
`;

export const StyledHeaderChildren = styled.div`
  @media (max-width: ${medias.tablet}) {
    width: 100%;
  }
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-end;

  a {
    font-size: 0.6875rem;
    color: ${colors.gray};
    font-weight: 500;
  }

  button {
    height: 100%;
  }

  & > a {
    font-size: 11px;
    line-height: 13px;

    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0.313rem 2rem 0.125rem 0.375rem;
    padding-right: 2rem;
    border: 1px solid #dddddd;
    border-radius: 3px;
    background-color: transparent;

    &:after {
      position: absolute;
      top: 50%;
      right: 0.625rem;
      display: block;
      width: 1rem;
      height: 1rem;
      background: url(${leankeepHat}) no-repeat center/contain;
      transform: translateY(-50%);
    }
  }
`;
export const RightSideHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledShowUserMenu = styled.div`
  width: 100%;
  max-width: 450px;

  @media screen and (max-width: ${medias.desktopSm}) {
    display: none;
  }
`;
