import { useContext, useEffect } from 'react';

import { AuthContext } from 'context/AuthContext';
import { AuthService } from 'services/main';
import { getLocalAuthStorage } from 'utils/localStorage';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { skeleton as initialHeadersFiltersState } from 'context/GlobalFiltersContext/initialState';
import { useCookies } from 'react-cookie';
import { removeHeaderFilterCookies } from 'utils/cookies';
import { URLS } from 'constants/urls';

const Logout = () => {
  const { setFiltersContext } = useContext(FiltersContext);
  const { authContext, setAuthContext } = useContext(AuthContext);
  let { token } = getLocalAuthStorage();
  const removeCookie = useCookies()[2];
  useEffect(() => {
    const logout = async () => {
      await AuthService.logout({ token });
    };
    logout()
      .then(() => {
        localStorage.setItem('socket', null);
        removeHeaderFilterCookies(removeCookie);
        setFiltersContext(initialHeadersFiltersState);

        setAuthContext({
          statusCode: false,
          isAuthenticated: false,
          token: null,
          refreshToken: null,
          expiresIn: null,
          user: {
            name: null,
          },
        });
        removeCookie('authToken', { path: '/', domain: URLS.cookieDomain });
        removeCookie('refreshToken', { path: '/', domain: URLS.cookieDomain });
        window.localStorage.clear();
        window.location.replace('/login');
      })
      .catch(error => {
        console.error({ error });
        window.localStorage.clear();
      });
  }, [authContext.stayConnected, removeCookie, setAuthContext, setFiltersContext, token]);
  return null;
};

export { Logout };
