import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ImageUploadContainer,
  Input,
  Label,
  SubLabel,
  PreviewImage,
  ButtonContainer,
  Button,
  ButtonTrash,
} from './styled';
import { ReactComponent as TrashcanIcon } from 'assets/icons/trashcan-icon.svg';

const ImageUpload = () => {
  const { control, setValue, watch } = useFormContext();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [borderDashed, setBorderDashed] = useState(true);
  const image = watch('image');

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setPreviewUrl(`${process.env.REACT_APP_URL_IMAGES}` + image);
        setBorderDashed(false);
      }
    } else {
      setPreviewUrl(null);
      setBorderDashed(true);
    }
  }, [image]);
  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setValue('image', selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setBorderDashed(false);
    }
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      setValue('image', selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleRemoveImage = () => {
    setValue('image', null);
    setPreviewUrl(null);
    setBorderDashed(true);
  };

  return (
    <Controller
      name="image"
      control={control}
      defaultValue={null}
      render={props => (
        <>
          <ImageUploadContainer
            borderDashed={borderDashed}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={event => {
              handleDrop(event);
              props.onChange(event.dataTransfer.files[0]);
            }}
            className={`drop-zone ${dragActive ? 'active' : ''}`}
          >
            <Input
              type="file"
              accept="image/*"
              onChange={event => {
                handleFileChange(event);
                props.onChange(event.target.files[0]);
              }}
              id="fileInput"
            />
            {!previewUrl && (
              <Label htmlFor="fileInput">
                Arraste um arquivo ou clique para fazer upload.
                <SubLabel>Apenas Imagens</SubLabel>
              </Label>
            )}
            <PreviewImage src={previewUrl} alt="Preview da Imagem" previewUrl={previewUrl} />
          </ImageUploadContainer>
          {previewUrl && (
            <ButtonContainer>
              <ButtonTrash type="button" onClick={handleRemoveImage}>
                <TrashcanIcon />
              </ButtonTrash>
              <Button type="button" onClick={() => document.getElementById('fileInput').click()}>
                Trocar imagem
              </Button>
            </ButtonContainer>
          )}
        </>
      )}
    />
  );
};

export default ImageUpload;
